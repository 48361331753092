import { StyleSheet, View } from "react-native"
import NormalIcon from "../icons/NormalIcon"
import Colors from "../../constants/Colors"
import { CustomText } from "../text/StyledText"
import { txt } from "../../locales/i18n"
import React from "react"

export default function SubscriptionBenefits() {
	return (
		<View style={[styles.points, {marginTop: 18}]}>
			<View style={styles.point}>
				<NormalIcon name="check" style={styles.pointIcon} size={14} color={Colors.greenIcon} />
				<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point1')}</CustomText>
			</View>

			<View style={styles.point}>
				<NormalIcon name="check" style={styles.pointIcon} size={14} color={Colors.greenIcon} />
				<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point2')}</CustomText>
			</View>

			<View style={styles.point}>
				<NormalIcon name="check" style={styles.pointIcon} size={14} color={Colors.greenIcon} />
				<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point3')}</CustomText>
			</View>

			<View style={styles.point}>
				<NormalIcon name="check" style={styles.pointIcon} size={14} color={Colors.greenIcon} />
				<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point4')}</CustomText>
			</View>

			<View style={styles.point}>
				<NormalIcon name="check" style={styles.pointIcon} size={14} color={Colors.greenIcon} />
				<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point5')}</CustomText>
			</View>

			<View style={styles.point}>
				<NormalIcon name="check" style={styles.pointIcon} size={14} color={Colors.greenIcon} />
				<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point6')}</CustomText>
			</View>

			<View style={styles.point}>
				<NormalIcon name="check" style={styles.pointIcon} size={14} color={Colors.greenIcon} />
				<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point7')}</CustomText>
			</View>

			<View style={styles.point}>
				<NormalIcon name="check" style={styles.pointIcon} size={14} color={Colors.greenIcon} />
				<CustomText style={styles.pointText}>{txt('subscription.dialogs.subscriptionDialog.point8')}</CustomText>
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	points: {
		gap: 8,
		marginLeft: 6,
		marginTop: 18,
	},

	point: {
		flexDirection: 'row',
		gap: 8,
	},

	pointIcon: {
		padding: 3,
		backgroundColor: Colors.greenExtraLight,
		borderRadius: 10,
	},

	pointText: {
		color: Colors.darkGrey,
		fontSize: 15,
	},
})