import React from 'react'
import CustomDialog from '../dialogs/CustomDialog'
import { CustomText } from '../text/StyledText'
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native'
import Colors from '../../constants/Colors'
import NormalIcon from '../icons/NormalIcon'
import { ButtonPrimary } from '../buttons/StyledButtons'
import { txt } from "../../locales/i18n"
import { handlePaymentClick } from "../../utils/handlePaymentClick"
import SubscriptionBenefits from "../shared/SubscriptionBenefits"

export default function PostFilterSubscriptionDialog({visible, close}) {

  async function handleOpenUrl() {
    const origin = 'filter-popup'
    if (Platform.OS === 'web') window.plausible('SubscriptionClick', { props: { origin }})
    close()

    await handlePaymentClick({ origin })
  }

  return (
    <CustomDialog visible={visible}>
      <View style={styles.dialogContainer}>
        <TouchableOpacity style={styles.closeIcon} accessibilityRole="button" onPress={close}>
          <NormalIcon name="times" color={Colors.inactive} />
        </TouchableOpacity>
        <CustomText style={styles.title}>
          {txt('subscription.dialogs.subscriptionFilterDialog.title')}
        </CustomText>
        <CustomText style={{ marginTop: 12 }}>
          {txt('subscription.dialogs.subscriptionFilterDialog.intro')}
        </CustomText>
        <CustomText style={{ marginTop: 12 }}>
          {txt('subscription.dialogs.subscriptionFilterDialog.benefits')}
        </CustomText>
        <SubscriptionBenefits />
      </View>
      <View style={{ paddingBottom: 24 }}>
        <ButtonPrimary
          onPress={handleOpenUrl}
          style={{ backgroundColor: Colors.greenBtn }}
          title={txt('subscription.dialogs.subscriptionDialog.button')}
          titleStyle={{ paddingHorizontal: 12 }}
        />
      </View>
    </CustomDialog>
  )
}

const styles = StyleSheet.create({
  dialogContainer: {
    margin: 24
  },
  closeIcon: {
    alignSelf: 'flex-end',
    position: 'absolute',
    paddingLeft: 24,
    zIndex: 1
  },
  title: {
    fontSize: 24,
    fontFamily: 'raleway-semibold',
    paddingRight: 25
  },
})