import React, { useEffect, useState } from 'react'
import { StyleSheet, View, ActivityIndicator } from 'react-native'
import Colors from '../../../constants/Colors'
import { CustomText } from '../../text/StyledText'
import { CornerStyles, TypographyStyles } from '../../../styles'
import { txt } from '../../../locales/i18n'
import NormalIcon from '../../icons/NormalIcon'
import Fontsizes from '../../../constants/Fontsizes'
import {
  createMessage,
  deleteMessage,
  getGroupMessageComments,
  getGroupMessageDocuments,
  getGroupMessageImages,
  pinGroupPost,
  unpinGroupPost,
  like,
  removeLike,
} from '../../../services/api/Groups'
import moment from 'moment'

import ScreeningConsentDialog from '../../dialogs/ScreeningConsentDialog'
import { getConversationId, getScreeningConsent } from '../../../services/api/Inbox'
import OptionButton from '../../buttons/OptionButton'
import OptionDialog from '../../dialogs/OptionsDialog'
import Likes from './Likes'
import { getHexColor } from '../../../utils/getHexColor'
import EditPostDialog from './dialogs/EditPostDialog'
import { addLinkToText } from '../../../utils/addLinkToText'
import DeletePostDialog from './dialogs/DeletePostDialog'
import PostPin from './PostPin'
import GroupCommentInput from './GroupCommentInput'
import { GroupPostFileLink } from './GroupPostFileLink'
import GroupPostImages from './GroupPostImages'
import Card from '../../Card'
import store from '../../../redux/store'
import CustomPressable from "../../pressables/CustomPressable"

export default function GroupPost({
  post,
  group,
  onInputFocus,
  refreshMessages,
  child,
  createPostComment,
  navigateToConversation,
  navigateToNewConversation,
  onInputChange,
  onInputBlur,
}) {
	moment.locale(txt('videoChat.locale'))
	const isAdmin = group?.own_user_rank === 1
	const isOwnPost = store.getState().user?.id === post.user_id

	const [body, setBody] = useState(post.body)
	const [liked, setLiked] = useState(post.hasLiked)
	const [pinned, setPinned] = useState(post.pinned)
	const [likeCount, setLikeCount] = useState(post.likes ?? 0)

	const [images, setImages] = useState([])
	const [comments, setComments] = useState([])
	const [documents, setDocuments] = useState([])

	const [hasMoreComments, setHasMoreComments] = useState(true)
	const [fetchingComments, setFetchingComments] = useState(false)

	const [options, setOptions] = useState([])
	const [optionsDialogTitle, setOptionsDialogTitle] = useState(undefined)

	const [showScreeningDialog, setShowScreeningDialog] = useState(false)
	const [showOptionsDialog, setShowOptionsDialog] = useState(false)
	const [showRemoveDialog, setShowRemoveDialog] = useState(false)
	const [showEditDialog, setShowEditDialog] = useState(false)

	useEffect(() => {
		const getResources = async () => {
			setFetchingComments(true)

			const commentsResponse = await getGroupMessageComments(group.id, post.id, 0)
			if (commentsResponse.comments.length === commentsResponse.totalComments) {
				setHasMoreComments(false)
			}

			commentsResponse.comments.reverse()
			setComments(commentsResponse.comments)
			setFetchingComments(false)

			if (!post.child) {
				const documents = await getGroupMessageDocuments(group.id, post.id)
				const images = await getGroupMessageImages(group.id, post.id)

				setDocuments(documents)
				setImages(images)
			}
		}

		getResources()
	}, [group, post])

	function updatePost(text) {
		if (text) setBody(text)
		toggleEditMessageDialog()
	}

	function toggleRemoveMessageDialog() {
		setShowRemoveDialog(!showRemoveDialog)
		setShowOptionsDialog(false)
	}

	function toggleEditMessageDialog() {
		setShowEditDialog(!showEditDialog)
		setShowOptionsDialog(false)
	}

  const editOption = {
    label: txt('groups.bulletin.edit'),
    hint: txt('groups.bulletin.editHint'),
    icon: "edit",
    action: toggleEditMessageDialog,
  }
  const removeOption = {
    label: txt('groups.bulletin.delete'),
    hint: txt('groups.bulletin.deleteHint'),
    icon: "trash",
    action: toggleRemoveMessageDialog,
    type: 'danger',
  }
  const sendMessageOption = {
    label: txt('groups.bulletin.sendMessage'),
    icon: "comment-lines",
    action: async () => {
      if (!post.user_id) return
      const conversationId = await getConversationId({
        groupId: group.id,
        receiverId: post.user_id,
      })
      if (conversationId) navigateToConversation(conversationId)
      else navigateToNewConversation(post.user_id)
      setShowOptionsDialog(false)
    },
  }

	function handleUserClick() {
		if (isOwnPost) return
		const options = [sendMessageOption]

		setOptions(options)
		setShowOptionsDialog(true)
		setOptionsDialogTitle(post.username)
	}

	function handleOptionButtonClick() {
		const options = []
		if (isOwnPost === false) options.push(sendMessageOption)
		if (isOwnPost || isAdmin) options.push(editOption, removeOption)

		setOptions(options)
		setShowOptionsDialog(true)
		setOptionsDialogTitle(undefined)
	}

	async function changePinnedPost() {
		pinned ? await unpinGroupPost(group.id, post.id) : await pinGroupPost(group.id, post.id)
		setPinned(!pinned)
		await refreshMessages()
	}

	async function sendComment(comment) {
		if (!comment) return
		const consentGiven = await getScreeningConsent()
		if (consentGiven === 0) return setShowScreeningDialog(true)

		const postData = {
			groupId: group.id,
			body: comment,
			images: null,
			parentId: post.id,
		}

		const message = await createMessage(postData)
		if (message !== undefined) setComments((comments) => [...comments, message])
	}

	async function handleDeletePost() {
		const wasDeleted = await deleteMessage(group.id, post.id)
		if (wasDeleted === false) return
		toggleRemoveMessageDialog()
		await refreshMessages()
	}

	async function getMoreComments() {
		setFetchingComments(true)
		const skip = comments.length

		if (hasMoreComments) {
			const res = await getGroupMessageComments(group.id, post.id, skip)
			if (res.comments.length + comments.length === res.totalComments) {
				setHasMoreComments(false)
			}

			res.comments.reverse()
			setComments((current) => [...res.comments, ...current])
		}

		setFetchingComments(false)
	}

	async function toggleLike() {
		liked ? await removeLike(group.id, post.id) : await like(group.id, post.id)
		setLikeCount(likeCount => likeCount + (liked ? -1 : 1))
		setLiked(!liked)
	}

  function ParentPost() {
    return (
      <>
        <View style={styles.postHeader}>
          <CustomPressable onPress={handleUserClick} style={{ flex: 1, flexDirection: 'row', alignItems: 'center', maxWidth: 500 }}>
            <View
              style={[styles.avatarContainer, { backgroundColor: getHexColor(post.user_id) }]}>
              <NormalIcon
                style={styles.avatar}
                accessibilityLabel={txt('fullPost.usernameIcon')}
                name="user"
                size={Fontsizes.s}
                color={Colors.white}
                stroke="fas"
              />
            </View>
            <View style={{ flex: 1 }}>
              <CustomText font="smallBold" style={{ color: Colors.blackTransparent82 }} numberOfLines={1}>
                {post.username}
              </CustomText>
              <CustomText font="small" style={styles.lightText}>
                {moment(post.created).format('DD-MM-YYYY HH:mm')}
              </CustomText>
            </View>
          </CustomPressable>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <PostPin
              isAdmin={isAdmin}
              isChildPost={child}
              isPinned={pinned}
              togglePin={changePinnedPost}
            />
            <OptionButton
              onPress={handleOptionButtonClick}
              size={20}
              hoverColor={Colors.skyBlue}
            />
          </View>
        </View>
        <View style={styles.postBody}>
          <CustomText style={TypographyStyles.text}>{addLinkToText(body)}</CustomText>
          {post.images > 0 && <GroupPostImages images={images} imageCount={post.images ?? 0} />}

          {documents.length !== 0 && (
            <View style={styles.filesContainer}>
              {documents.map(({ id, name }) => (
                <GroupPostFileLink key={id} id={id} name={name} groupId={group.id} />
              ))}
            </View>
          )}
        </View>
        <Likes
          hasLiked={liked}
          likes={likeCount}
          toggleLike={toggleLike}
          groupId={group.id}
          msgId={post.id}
        />
        <View style={styles.commentSectionLine} />
      </>
    )
  }

  const renderMoreCommentButton = () => {
    const totalComments = post.totalComments ?? 0
    if (totalComments === 0 || child || hasMoreComments === false) return null

    return (
      <CustomPressable onPress={getMoreComments} disabled={fetchingComments} style={styles.moreCommentsButton}>
        {fetchingComments === false && (
          <CustomText font="smallBold" style={{ color: Colors.tintColor }}>
            {txt('groups.bulletin.showMoreComments')}
          </CustomText>
        )}
        {fetchingComments === true && <ActivityIndicator color={Colors.tintColor} />}
      </CustomPressable>
    )
  }

  return (
    <Card
      style={[!child ? styles.postContainer : {
        paddingLeft: 20,
        shadowColor: Colors.transparent,
        elevation: 0,
      }, { backgroundColor: group?.theme?.secondary || Colors.white }]}>
      {ParentPost()}
      {renderMoreCommentButton()}
      {comments.map((comment) => (
        <GroupPost
          key={comment.id.toString()}
          post={comment}
          child={true}
          group={group}
          refreshMessages={refreshMessages}
          createPostComment={createPostComment}
          navigateToConversation={navigateToConversation}
          navigateToNewConversation={navigateToNewConversation}
        />
      ))}

      {!child &&
        <GroupCommentInput
          group={group}
          sendComment={sendComment}
          onChange={onInputChange}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
        />
      }

      <ScreeningConsentDialog
        visible={showScreeningDialog}
        handleHideDialog={() => setShowScreeningDialog(false)}
      />

      <OptionDialog
        visible={showOptionsDialog}
        onDismiss={() => setShowOptionsDialog(false)}
        options={options}
        title={optionsDialogTitle}
      />

      <DeletePostDialog
        visible={showRemoveDialog}
        dismiss={toggleRemoveMessageDialog}
        parentId={post?.parent_message_id || null}
        deletePost={handleDeletePost}
      />

      <EditPostDialog
        visible={showEditDialog}
        dismiss={toggleEditMessageDialog}
        post={post}
        updatePost={updatePost}
        groupId={group.id}
      />
    </Card>
  )
}

const styles = StyleSheet.create({
  avatar: {
    alignSelf: 'center',
  },
  avatarContainer: {
    borderRadius: 100,
    opacity: 0.4,
    width: 31,
    height: 31,
    justifyContent: 'center',
    marginRight: 6,
  },
  commentSectionLine: {
    borderBottomColor: Colors.blackTransparent,
    borderBottomWidth: 1,
    marginBottom: 10,
    opacity: 0.9,
  },
  lightText: {
    color: Colors.inactive,
  },
  postBody: {
    marginVertical: 12,
  },
  postContainer: {
    ...CornerStyles.borderRadiusS,
    flexDirection: 'column',
    marginBottom: 12,
    padding: 10,
	marginHorizontal: 10,
  },
  postHeader: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  moreCommentsButton: {
    paddingTop: 10,
    paddingBottom: 14,
    paddingLeft: 20,
    display: 'flex',
    alignItems: 'flex-start',
  },
})
