import React, { useEffect, useState, useRef, useCallback } from 'react'
import { FlatList, StyleSheet, Platform, AppState, ActivityIndicator } from 'react-native'
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs'
import { useFocusEffect, useNavigation } from '@react-navigation/core'
import { useSelector } from 'react-redux'

import * as GroupService from '../../../services/api/Groups'
import GroupPost from './GroupPost'
import GroupInput from './GroupInput'

import { useKeyboardHeight } from '../../../utils/hooks'
import { SpacingStyles } from '../../../styles'
import Colors from '../../../constants/Colors'

export default function GroupBulletin(props) {
	const group = props.group ?? null
	const groupId = group?.id
	const navigation = useNavigation()
	const themeColor = group?.theme?.primary ?? Colors.tintColor

	const [posts, setPosts] = useState([])
	const [loading, setLoading] = useState(false)
	const [refreshing, setRefreshing] = useState(false)
	const [endReached, setEndReached] = useState(false)

	const listRef = useRef(null)
	const [activePostIndex, setActivePostIndex] = useState(null)
	const keyboardHeight = useKeyboardHeight()
	const bottomTabBarHeight = useBottomTabBarHeight()

	const groupRedux = useSelector((state) => state.groups?.find((g) => g.id === props.group.id))

	useEffect(() => {
		const subscription = AppState.addEventListener('change', (state) => {
			if (state === 'active' && Platform.OS !== 'web') refresh()
		})

		return () => subscription?.remove()
	}, [groupId, navigation])

	useFocusEffect(
		useCallback(() => {
			GroupService.readAllGroupMessages({ id: props.group.id })
		}, [groupRedux?.unread])
	)

	useEffect(() => {
		scrollUpdate()
	}, [activePostIndex, keyboardHeight])

	const scrollUpdate = () => {
		if (Platform.OS !== 'ios') return
		if (activePostIndex === null || keyboardHeight === 0) return

		const list = listRef.current
		const viewOffset = (keyboardHeight - bottomTabBarHeight) * -1
		list.scrollToIndex({ index: activePostIndex, animated: true, viewPosition: 1, viewOffset })
	}

	const getPosts = async (skip = 0) => {
		const paginationCount = 10
		const posts = await GroupService.getGroupMessages(groupId, skip, paginationCount)
		skip === 0 ? setPosts(posts) : setPosts((current) => [...current, ...posts])

		setEndReached(posts.length < paginationCount)
	}

	const paginate = useCallback(async () => {
		if (loading || refreshing || endReached) return

		setLoading(true)
		await getPosts(posts.length)
		setLoading(false)
	}, [loading, refreshing, endReached])

	const refresh = async () => {
		if (refreshing || loading) return

		setPosts([])
		setRefreshing(true)
		await getPosts(0)
		setRefreshing(false)
	}

	const renderPost = ({ item, index }) => (
		<GroupPost
			post={item}
			child={false}
			group={group}
			refreshMessages={refresh}
			navigateToConversation={props.navigateToConversation}
			navigateToNewConversation={props.navigateToNewConversation}
			onInputChange={scrollUpdate}
			onInputFocus={() => setActivePostIndex(index)}
			onInputBlur={() => setActivePostIndex(null)}
		/>
	)

	const ListHeader = <GroupInput group={group} onMessageCreated={refresh} />
	const ListFooter = (loading || refreshing) && <ActivityIndicator color={themeColor} style={styles.loader} />

	return (
		<FlatList
			// states
			data={posts}
			refreshing={refreshing}
			style={[styles.list, props.style]}
			// utils
			ref={listRef}
			keyExtractor={({ id }) => id.toString()}
			// components
			renderItem={renderPost}
			ListHeaderComponent={ListHeader}
			ListFooterComponent={ListFooter}
			// handlers
			onRefresh={refresh}
			onEndReached={paginate}
			// options
			onEndReachedThreshold={0.5}
			keyboardDismissMode="on-drag"
			onMomentumScrollBegin={() => setActivePostIndex(null)}
		/>
	)
}

const styles = StyleSheet.create({
	list: {
		flex: 1,
		...SpacingStyles.widthAndHeight,
	},

	loader: {
		margin: 12,
		textAlign: 'center',
	},
})
