import { apiDelete, apiGet, apiPost, apiPut } from './ApiRequest'
import { emitAllMessagesRead, emitNewMessage } from '../socketService'
import { txt } from '../../locales/i18n'
import store from '../../redux/store'
import Paths from '../../constants/Paths'
import {
  addConversation,
  addConversationMessage,
  deleteConversationMessage as deleteConversationMessageRedux,
  setConversations,
  updateConversation,
} from '../../redux/actions'


export async function getConversations() {
  const res = await apiGet('conversations')
  const conversations = res?.data?.newResult ?? []

  conversations.forEach(conv => conv = addTitleToConversation(conv))
  conversations.sort((a, b) => {
    if ((a.messages?.[0].created || a.created) > (b.messages?.[0].created || b.created))
      return -1
    else
      return 1
  })
  store.dispatch(setConversations(conversations))
  return conversations
}

export async function deleteConversationMessage(conversationID, messageID) {
  const res = await apiDelete(
    {},
    `conversations/${conversationID}/messages/${messageID}/delete`
  )
  if (res.ok) {
    store.dispatch(deleteConversationMessageRedux(conversationID, messageID))
  }
}

export async function editConversationMessage(
  conversationId,
  message_id,
  text
) {
  //conversations/:conversationId/messages
  // JsonData to send to api
  const data = {
    body: text,
  }

  // Wait for response then parse to json
  return await apiPost(
    data,
    `conversations/${conversationId}/messages/${message_id}/edit`
  )
    .then((response) => response.json())
    .then((responseJson) => {
      if (__DEV__) console.log(responseJson.data[0])
      return responseJson.data[0]
    })
    .catch((error) => {
      if (__DEV__) console.log(error)
    })
}

export async function getConversationMessages(conversationId, offset = 0) {
  const res = await apiGet(`conversations/${conversationId}/messages/${offset}`)
  if (res.data) {
    const conversation = {
      conversation_id: conversationId,
      messages: res.data,
    }
    store.dispatch(updateConversation(conversation))
    return res.data
  } else {
    return []
  }
}

export async function sendConversationMessage(conversationId, text) {
  const data = { body: text }
  let res = await apiPost(data, `conversations/${conversationId}/messages/`)
  const resJson = await res.json()
  if (res.ok) {
    store.dispatch(addConversationMessage(conversationId, resJson.message))
    await emitNewMessage(resJson.message)
  }
  return resJson
}

export async function createConversation({
                                           postId,
                                           groupId,
                                           slSessionId,
                                           vsSessionId,
                                           receiverId,
                                           christmasPostIdInitiator,
                                           christmasPostIdReceiver,
                                         }) {
  const data = {
    postId,
    groupId,
    slSessionId,
    vsSessionId,
    receiverId,
    christmasPostIdInitiator,
    christmasPostIdReceiver,
  }
  const result = await apiPost(data, 'conversations').then((res) => res.json())
  if (result.error) {
    return result
  }
  const conversation = addTitleToConversation(result.data)
  await store.dispatch(addConversation(conversation))
  return conversation
}

export async function createGroupConversation(userIds) {
  let res = await apiPost({userIds}, 'conversations')
  if (!res.ok) return
  res = await res.json()
  const conversation = addTitleToConversation(res.data)
  await store.dispatch(addConversation(conversation))
  return conversation
}

export async function leaveConversation(conversationId) {
  return apiDelete({}, `conversations/${conversationId}`)
}

export async function getConversationId({
                                          postId,
                                          groupId,
                                          slSessionId,
                                          vsSessionId,
                                          receiverId,
                                          christmasPostIdInitiator,
                                          christmasPostIdReceiver,
                                        }) {
  let query = 'conversations?'
  if (postId) query += `postId=${postId}&`
  if (groupId) query += `groupId=${groupId}&`
  if (slSessionId) query += `slSessionId=${slSessionId}&`
  if (vsSessionId) query += `vsSessionId=${vsSessionId}&`
  if (receiverId) query += `receiverId=${receiverId}&`
  if (christmasPostIdInitiator) query += `christmasPostIdInitiator=${christmasPostIdInitiator}&`
  if (christmasPostIdReceiver) query += `christmasPostIdReceiver=${christmasPostIdReceiver}&`
  const res = await apiGet(query)
  if (!res.data) return null
  return res.data.id
}

export async function createJVConversation(
  receiverId,
  receivingPostId,
  initiatingPostId
) {
  const data = { receiverId, receivingPostId, initiatingPostId }
  const result = await apiPost(data, 'conversations').then((res) => res.json())
  return result.data[0].conversation_id
}

export async function getJVConversationId(
  receiverId,
  receivingPostId,
  initiatingPostId
) {
  const res = await apiGet(
    `conversations` +
      `?christmas_post_id_receiver=${receivingPostId}` +
      `&christmas_post_id_initiater=${initiatingPostId}` +
      `&receiver_id=${receiverId}`
  )
  return res.data.conversation_id
}

export async function getJVConversationWithUser(
  receiverUserId,
  postInitiater,
  postReceiver
) {
  const data = {
    user_id_receiver: receiverUserId,
    post_id_initiater: postInitiater,
    post_id_receiver: postReceiver,
  }

  // Wait for response then parse to json
  return apiPost(data, `conversations/getOrCreateJV/`)
    .then((response) => response.json())
    .then((responseJson) => {
      if (!responseJson.data[0].conversation_id) {
        return
      }
      return responseJson.data[0].conversation_id
    })
    .catch((error) => {
      if (__DEV__) console.log(error)
    })
}

// Deprecated from 21/03/2022 - Remove later
export async function giveScreeningConsent() {
  return apiGet(`conversations/screeningConsent`)
}

export async function updateScreeningConsent(consentGiven) {
  let res = await apiPut({ consentGiven }, 'conversations/screeningConsent')
  if (!res.ok) return
  res = await res.json()
  return res
}

export async function getScreeningConsent() {
  const res = await apiGet('conversations/getScreeningConsent')
  return res.data
}

export async function readAllMessages(conversationID) {
  store.dispatch(
    updateConversation({
      conversation_id: conversationID,
      unread_messages: 0,
    })
  )
  try {
    await apiPost({}, `conversations/${conversationID}/readAll`)
    await emitAllMessagesRead({ conversationID })
  } catch (err) {
    return false
  }

  return true
}

export async function getUnreadMessages(conversationId) {
  const res = await apiGet(`conversations/${conversationId}/unread-messages`)
  return res ?? null
}

export async function editConversationTitle(title, conversationId) {
  const result = await apiPut({ title }, `conversations/${conversationId}`)
  if (!result) return
  const resJson = await result.json()
  store.dispatch(updateConversation({ conversation_id: conversationId, title: resJson.title }))
  return resJson.title
}

export async function getConversationFeature(conversationId) {
  if (!conversationId) return false
  const result = await apiGet(`conversations/getConversationFeature/${conversationId}`)
  if (!result) return false
  return result.data
}

function addTitleToConversation(conversation) {
  conversation.otherUsers = conversation.users.filter(
    (user) => user.id != store.getState().user.id,
  )
  if (conversation.title) return conversation
  if (conversation.otherUsers.length) {
    const usersNames = conversation.otherUsers.map((user) => user.username)
    conversation.title =
      usersNames.join(', ') +
      (conversation.post_title
        ? ' ∙ ' + conversation.post_title
        : conversation.group_name
          ? ' ∙ ' + conversation.group_name
          : conversation.sl_session_id
            ? ' ∙ ' + txt('learnDanish.title')
            : conversation.vs_session_id
              ? ' ∙ ' + txt('videoChat.title')
              : '')
  } else {
    conversation.title =
      conversation.post_title ||
      conversation.group_name ||
      txt('conversations.conversationWithMyself')
  }
  return conversation
}

export async function createMessage(message, conversationId) {
  const url = `conversations/${conversationId}/messages`
  const response = await apiPost(message, url)
  const json = await response.json()

  if (response.ok) {
    store.dispatch(addConversationMessage(conversationId, json.message))
    await emitNewMessage(json.message)
  }

  return json ?? null
}

export async function getAttachmentTempLink(conversationId, attachmentId) {
  const response = await apiGet(`conversations/${conversationId}/attachments/${attachmentId}/link`)
  if (!response) return null
  return Paths.apiBaseUrl + `conversations/${conversationId}/attachments/${response.tempAttachmentId}`
}