import React, { useState } from 'react'
import { Platform, StyleSheet, TextInput, View } from 'react-native'
import { txt } from '../../../locales/i18n'
import NormalIcon from '../../icons/NormalIcon'
import Fontsizes from '../../../constants/Fontsizes'
import Colors from '../../../constants/Colors'
import LoadingSpinner from '../../activityIndicators/LoadingSpinner'
import { CornerStyles, TypographyStyles } from '../../../styles'
import CustomPressable from "../../pressables/CustomPressable"

export default function GroupCommentInput({ sendComment, group, onChange, onFocus, onBlur }) {
  const [text, setText] = useState('')
  const [commentHeight, setCommentHeight] = useState(40)
  const [loading, setLoading] = useState(false)

  const handleCommentChange = (text) => {
    if (text === '') setCommentHeight(40)
    setText(text)
    onChange?.()
  }

  const handleContentHeight = (e) => {
    setCommentHeight(e.nativeEvent.contentSize.height)
  }

  const handleSendComment = () => {
    setLoading(true)
    sendComment(text)
    setCommentHeight(40)
    setText('')
    setLoading(false)
  }

  return (
    <View>
      <View style={styles.inputContainer}>
        <TextInput
          accessibilityLabel={txt('groups.bulletin.accessibilityLabel')}
          accessibilityHint={txt('groups.bulletin.accessibilityHint')}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={txt('groups.bulletin.placeholder')}
          onChangeText={handleCommentChange}
          onContentSizeChange={handleContentHeight}
          value={text}
          style={[styles.comment, {
            backgroundColor: group.theme?.secondary || Colors.white,
            height: Platform.OS === 'web' ? commentHeight : null
          }]}
          multiline={true}
          spellCheck={true}
          enablesReturnKeyAutomatically={true}
        />
        {loading ? (
          <LoadingSpinner style={styles.loadingSpinner} />
        ) : (
          <CustomPressable onPress={handleSendComment}>
            <NormalIcon
              style={styles.marginLeft}
              name="paper-plane"
              size={Fontsizes.l}
              color={text ? group.theme?.primary || Colors.tintColor : Colors.inactive}
              stroke={text ? "fas" : "fal"}
              accessibilityRole="button"
              accessibilityLabel={txt('groups.bulletin.send')}
            />
          </CustomPressable>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingRight: 10,
  },
  marginLeft: { marginLeft: 10 },
  loadingSpinner: { marginBottom: 0, marginTop: 0, marginLeft: 10 },
  comment: {
    ...TypographyStyles.text,
    ...CornerStyles.borderRadiusM,
    flex: 1,
    marginLeft: 15,
    maxHeight: 300,
    minHeight: 40,
    borderWidth: 1,
    borderColor: Colors.shadow,
    backgroundColor: Colors.white,
    paddingHorizontal: 18,
    paddingTop: 9, // If higher than 9, padding will make text overflow and scroll
    paddingBottom: 9, // If higher than 9, padding will make text overflow and scroll
  },
})
