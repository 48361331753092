import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet, Platform, View } from 'react-native'
import { GiftedChat } from 'react-native-gifted-chat'

import moment from 'moment'
import 'moment/min/locales' //needs to be there to update locale to another countrycode.

import { ChatBubble } from './ChatBubble'
import { ChatAttachmentToolbar } from './ChatAttachmentToolbar'
import { ErrorNormalSnackbar } from '../../../snackbars/Snackbar'

import { TypographyStyles } from '../../../../styles'
import Colors from '../../../../constants/Colors'
import { txt } from '../../../../locales/i18n'

import { MessageTextInput } from '../../../text/StyledTextinputs'
import NormalIcon from '../../../icons/NormalIcon'
import Fontsizes from '../../../../constants/Fontsizes'
import CustomPressable from "../../../pressables/CustomPressable"
import { CustomText } from "../../../text/StyledText"
import store from "../../../../redux/store"

const deletedStatuses = [
  -3, // deletedByUser
  -4, // deletedByAdmin
]

export const Chat = (props) => {
  const [messages, setMessages] = useState(null)
  const hasFeature1 = store.getState().user?.features?.includes(1)

  useEffect(() => {
    const messages = props.messages.map((message) => {
      message._id = message.id
      message.text = message.body
      message.createdAt = message.created
      message.user = { _id: message.user_id, name: message.username }
      message.removed = false

      if (deletedStatuses.includes(message.status_id)) {
        message.removed = true
        message.createdAt = undefined
        message.text = txt('conversation.messageHasBeenDeleted')
        return message
      }

      if (!message.announcement_id) return message
      message.system = true

      if (props.user.id === message.user_id) {
        message.text = txt(`conversations.ownAnnouncement.${message.announcement_id}`)
        return message
      }

      message.text = `${message.username} ${txt(`conversations.announcement.${message.announcement_id}`)}`
      return message
    })

    setMessages(messages)
  }, [props.messages])

  const renderBubble = (post) => {
    return (
      <ChatBubble
        route={props.route}
        renderOptionsButton={props.optionsButton}
        showOptionsButton={post.currentMessage.user_id === post.user._id}
        {...post}
      />
    )
  }

  const renderBubbleWithRead = (post) => {
    const { lastRead, lastUnread, user_id } = post.currentMessage

    const Label = ({ text, stroke }) => (
      <View style={styles.label}>
        <CustomText style={styles.labelText}>{text}</CustomText>
        <NormalIcon size={Fontsizes.xs} name="check-circle" color={Colors.grey} stroke={stroke} />
      </View>
    )

    return (
      <View style={{ maxWidth: '100%' }}>
        {renderBubble(post)}
        {lastRead && user_id === post.user._id ? (
          <Label stroke="fas" text={txt('conversation.messageRead')} />
        ) : lastUnread && user_id === post.user._id ? (
          <Label stroke="fal" text={txt('conversation.messageDelivered')} />
        ) : null}
      </View>
    )
  }

  if (messages === null) return null

  return (
    <>
      <GiftedChat
        isKeyboardInternallyHandled={false}
        minInputToolbarHeight={0}
        messages={messages}
        renderUsernameOnMessage
        user={{ _id: props.user.id }}
        locale={moment.locale()}
        timeFormat={txt('conversations.timeFormat')}
        textStyle={styles.messageText}
        onLongPress={props.onLongPress}
        onPressAvatar={props.onPressAvatar}
        renderInputToolbar={() => null}
        renderBubble={hasFeature1 ? renderBubbleWithRead : renderBubble}
      />

      <InputToolbar
        isInitialMessage={messages.length === 0}
        handleSendMessage={props.onSendMessage}
        showAttachmentButton={props.showAttachmentButton}
        validateText={props?.validateText}
      />
    </>
  )
}

const InputToolbar = (props) => {
  const [message, setMessage] = useState('')
  const [attachments, setAttachments] = useState([])
  const [sendErrorMessage, setSendErrorMessage] = useState(null)
  const [showToolbar, setShowToolbar] = useState(false)
  const [isSendingMessage, setIsSendingMessage] = useState(false)
  const [inputHeight, setInputHeight] = useState(30)
  const validateInputTimeoutRef = useRef(null)

  const handleMessageChange = (message) => {
    setMessage(message)
    if (message === '') return setInputHeight(30)

    if (props.isInitialMessage) {
      if (validateInputTimeoutRef.current !== null) clearTimeout(validateInputTimeoutRef.current)
      validateInputTimeoutRef.current = setTimeout(() => props?.validateText(message), 400)
    }
  }

  const handleSendMessage = async () => {
    setIsSendingMessage(true)

    try {
      const success = await props.handleSendMessage(message, attachments)

      if (success) {
        setInputHeight(30)
        setMessage('')
        setAttachments([])
        setShowToolbar(false)
      }
    } catch (error) {
      if (__DEV__) console.error(error)
      setSendErrorMessage(txt('apiError.UNKNOWN'))
    }

    setIsSendingMessage(false)
  }

  return (
    <>
      <ChatAttachmentToolbar visible={showToolbar} setAttachments={setAttachments} attachments={attachments} />

      <View style={styles.inputContainer}>
        <View style={styles.input}>
          <MessageTextInput
            placeholder={txt('conversations.writeMessagePlaceHolder')}
            onChangeText={handleMessageChange}
            onContentSizeChange={({ nativeEvent }) => setInputHeight(nativeEvent.contentSize.height)}
            height={Platform.OS === 'web' ? inputHeight : null}
            value={message}
          />
        </View>

        {props.showAttachmentButton && (
          <CustomPressable
            accessible
            accessibilityRole="button"
            accessibilityLabel={txt('conversation.addAttachment')}
            accessibilityHint={txt('conversation.addAttachmentHint')}
            disabled={attachments.length !== 0}
            onPress={() => setShowToolbar((showToolbar) => !showToolbar)}
            style={{ padding: 10 }}
          >
            <NormalIcon
              size={Fontsizes.l}
              name={showToolbar ? "chevron-down" : "paperclip"}
              color={showToolbar && attachments.length !== 0 ? Colors.grey : Colors.tintColor}
              stroke="fal"
            />
          </CustomPressable>
        )}

        <CustomPressable
          accessible
          accessibilityRole="button"
          accessibilityLabel={txt('conversations.messageSend')}
          accessibilityHint={txt('conversations.messageSendHint')}
          disabled={isSendingMessage}
          onPress={handleSendMessage}
          style={{ padding: 10 }}
        >
          <NormalIcon
            size={Fontsizes.l}
            name="paper-plane"
            color={!message || isSendingMessage ? Colors.grey : Colors.tintColor}
            stroke={!message || isSendingMessage ? "fal" : "fas"}
          />
        </CustomPressable>
      </View>

      <ErrorNormalSnackbar visible={sendErrorMessage !== null} onDismiss={() => setSendErrorMessage(null)}>
        {sendErrorMessage}
      </ErrorNormalSnackbar>
    </>
  )
}

const styles = StyleSheet.create({
  messageText: {
    color: Colors.text,
    ...TypographyStyles.textSmallBold,
  },

  input: {
    flex: 1,
    marginLeft: 10,
    maxHeight: 130,
    minHeight: 40,
  },

  inputContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 12,
  },

  label: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 3,
    alignSelf: 'flex-end',
  },

  labelText: {
    paddingRight: 6,
    fontSize: 11,
    color: Colors.grey,
  }
})
