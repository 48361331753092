import React, { useState } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { txt } from '../../../locales/i18n'
import Layout from '../../../constants/Layout'
import ImageSlider from './ImageSlider'
import CustomPressable from '../../pressables/CustomPressable'
import { Skeleton } from '../../activityIndicators/Skeleton'

export default function GroupPostImages({ images, imageCount }) {
	const [startIndex, setStartIndex] = useState(undefined)

	if (images.length !== imageCount) {
		const style = imageCount === 1 ? styles.imageSingleWrapper : styles.imageMultipleWrapper

		return (
			<View style={styles.container}>
				{Array.from({ length: imageCount }).map((_, index) => (
					<Skeleton key={index} style={style} />
				))}
			</View>
		)
	}

	return (
		<View style={styles.container}>
			{images.map((image, index) => (
				<CustomPressable
					key={index.toString()}
					style={images.length === 1 ? styles.imageSingleWrapper : styles.imageMultipleWrapper}
					accessibilityLabel={txt('groups.bulletin.imageLabel')}
					accessibilityHint={txt('groups.bulletin.imageHint')}
					onPress={() => setStartIndex(index)}
				>
					<Image
						style={styles.image}
						source={{ uri: image.base64 }}
						accessibilityIgnoresInvertColors
						resizeMode="cover"
					/>
				</CustomPressable>
			))}
			<ImageSlider
				visible={startIndex !== undefined}
				dismiss={() => setStartIndex(undefined)}
				images={images}
				startIndex={startIndex}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'center',
		paddingTop: 12,
		width: '100%',
		gap: 1,
	},

	imageMultipleWrapper: {
		flex: 1,
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: Layout.isSmallDevice ? 135 : 200,
		height: Layout.isSmallDevice ? 135 : 200,
	},

	imageSingleWrapper: {
		flex: 1,
		height: 400,
		width: '100%',
	},

	image: {
		width: '100%',
		height: '100%',
	},
})
