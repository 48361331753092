import socketIO from 'socket.io-client'
import Paths from '../constants/Paths'
import store from '../redux/store'
import { addConversationMessage, setConversations, updateConversation } from '../redux/actions'
import { apiGet } from "./api/ApiRequest"
import { txt } from "../locales/i18n"

let socket = null
let loading = false

export async function connectSocket(accessToken) {
  // Dont continue if connect is already loading, sometimes called twice
  if (loading || socket != null) return
  loading = true

  let userId = store.getState().user.id
  if (!userId) {
    loading = false
    return
  }

  socket = socketIO(Paths.socketIOBaseUrl, {
    auth: {token: accessToken },
    transports: ['websocket'],
    cookie: false,
  })

  socket.on('connect', () => {
    if (__DEV__) console.log('Socket.io connected')
  })

  socket.on('disconnect', () => {
    if (__DEV__) console.log('Socket.io disconnected')
  })

  socket.on('newMessage', async (message) => {
    if (__DEV__) console.log('newMessage from socket', message)
    let conversations = store.getState().conversations
    let conversation = conversations.find(
      (c) => c.conversation_id === message.conversationID
    )
    const ownMessage = message.user_id === userId
    if (conversation) {
      store.dispatch(
        updateConversation({
          conversation_id: conversation.conversation_id,
          unread_messages: ownMessage
            ? conversation.unread_messages
            : conversation.unread_messages + 1,
        })
      )
    } else {
      await getConversationsSocketService() // todo: only get one conversation
    }
    !ownMessage && playNotificationSoundSocketService()
    store.dispatch(addConversationMessage(message.conversationID, message))
  })

  socket.on('messagesRead', async (message) => {
    if (__DEV__) console.log('messagesRead from socket', typeof message.conversationID, message.conversationID)
    let conversations = store.getState().conversations
    let conversation = conversations.find((c) => c.conversation_id === message.conversationID)
    if (conversation) {
      store.dispatch(updateConversation({ conversation_id: message.conversationID, reloadConversation: true }))
    }
  })

  socket.connect()
  loading = false
}

export async function disconnectSocket() {
  if (__DEV__) console.log('Disconnecting socketIO')
  try {
    if (socket.connected) await socket.disconnect()
    socket = null
  } catch {
    if (__DEV__) console.log('Socket already disconnected')
  }
}

export async function emitNewMessage(message) {
  if (!socket || !socket.connected) return
  const conversations = store.getState().conversations
  const conversation = conversations.find(
    (conv) => conv.conversation_id === message.conversationID
  )
  const userIds = conversation?.users.map((user) => user.id)
  socket.emit('newMessage', userIds, message)
}

export async function emitAllMessagesRead(message) {
  if (!socket || !socket.connected) return
  const conversations = store.getState().conversations
  const conversation = conversations.find((conv) => conv.conversation_id === message.conversationID)
  const userIds = conversation?.users.map((user) => user.id)
  socket.emit('messagesRead', userIds, message)
}

export async function getConversationsSocketService() {
  const res = await apiGet('conversations')
  const conversations = res?.data?.newResult ?? []

  conversations.forEach(conv => conv = addTitleToConversationSocketService(conv))
  conversations.sort((a, b) => {
    if ((a.messages?.[0].created || a.created) > (b.messages?.[0].created || b.created))
      return -1
    else
      return 1
  })
  store.dispatch(setConversations(conversations))
  return conversations
}

function addTitleToConversationSocketService(conversation) {
  conversation.otherUsers = conversation.users.filter(
    (user) => user.id != store.getState().user.id,
  )
  if (conversation.title) return conversation
  if (conversation.otherUsers.length) {
    const usersNames = conversation.otherUsers.map((user) => user.username)
    conversation.title =
      usersNames.join(', ') +
      (conversation.post_title
        ? ' ∙ ' + conversation.post_title
        : conversation.group_name
          ? ' ∙ ' + conversation.group_name
          : conversation.sl_session_id
            ? ' ∙ ' + txt('learnDanish.title')
            : conversation.vs_session_id
              ? ' ∙ ' + txt('videoChat.title')
              : '')
  } else {
    conversation.title =
      conversation.post_title ||
      conversation.group_name ||
      txt('conversations.conversationWithMyself')
  }
  return conversation
}

export async function playNotificationSoundSocketService() {
  // try {
  //   const { sound } = await Audio.Sound.createAsync(require('../assets/sounds/bobble.mp3'))
  //   sound.playAsync().then(setTimeout(sound.unloadAsync), 1000).catch()
  // } catch (err) {
  //   if (__DEV__) console.error(err)
  // }
}