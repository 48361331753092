import { Actions } from './constants'

const initialState = {
  user_post: null,
  announcement: '',
  dataResponsible: '',
  user: null,
  userToken: null,
  deviceId: null,
  conversations: [],
  showMakeInfluence: false,
  municipalities: [],
  origin: {
    stack: null,
    screen: null,
    universe: null,
  },
  universe: '',
  groups: null,
  preferences: null,
  christmas: {
    post: null,
    dialogVisible: false,
    matchDialogVisible: false,
  },
  globalDialog: {
    visible: false,
    type: null,
    text: '',
    title: '',
    data: {},
  }
}

function rootReducer(state = initialState, action) {
  if (action.type === Actions.RESET_STATE) {
    return initialState
  }
  if (action.type === Actions.SET_OWN_POST) {
    return Object.assign({}, state, {
      user_post: action.payload,
    })
  }
  if (action.type === Actions.UPDATE_OWN_POST) {
    return Object.assign({}, state, {
      user_post: { ...state.user_post, ...action.payload },
    })
  }
  if (action.type === Actions.SET_ANNOUNCEMENT) {
    return Object.assign({}, state, {
      announcement: action.payload,
    })
  }
  if (action.type === Actions.SET_DATA_RESPONSIBLE) {
    return Object.assign({}, state, {
      dataResponsible: action.payload,
    })
  }
  if (action.type === Actions.SET_USER_TOKEN) {
    return Object.assign({}, state, {
      userToken: action.payload,
    })
  }

  if (action.type === Actions.SET_DEVICE_ID) {
    return Object.assign({}, state, {
      deviceId: action.payload,
    })
  }

  if (action.type === Actions.SET_USER) {
    return Object.assign({}, state, {
      user: action.payload,
    })
  }
  if (action.type === Actions.SET_ORIGIN) {
    return {
      ...state,
      origin: {
        ...state.origin,
        ...action.payload,
      },
    }
  }

  if (action.type === Actions.SET_CONVERSATIONS) {
    const conversations = action.payload.map((conversation) => {
      conversation.reloadConversation = false
      const storedConversation = state.conversations.find(({ conversation_id }) => {
        return conversation_id === conversation.conversation_id
      })

      if (!storedConversation) return conversation

      const conversationMessages = conversation?.messages ?? null
      if (conversationMessages === null) return storedConversation

      const currentLastMessageIndex = (conversation?.messages?.length ?? 0) - 1
      const currentLastMessage = conversation.messages[currentLastMessageIndex] ?? null
      const [previousLastMessage] = storedConversation.messages

      if (currentLastMessage === null) return storedConversation

      if (currentLastMessage?.id !== previousLastMessage?.id) {
        storedConversation.messages.unshift(currentLastMessage)
      }

      return storedConversation
    })

    state.conversations = conversations
    return state
  }

  if (action.type === Actions.ADD_CONVERSATION) {
    return {
      ...state,
      conversations: [action.conversation, ...state.conversations],
    }
  }
  if (action.type === Actions.REMOVE_CONVERSATION) {
    return {
      ...state,
      conversations: state.conversations.filter(
        (item) => item.conversation_id !== action.payload
      ),
    }
  }

  if (action.type === Actions.UPDATE_CONVERSATION) {
    const conversations = state.conversations.map((conv) => {
      return conv.conversation_id === action.conversation.conversation_id
        ? { ...conv, ...action.conversation }
        : conv
    })
    return { ...state, conversations }
  }

  if (action.type === Actions.ADD_CONVERSATION_MESSAGE) {
    const conversation = state.conversations.find(
      (conv) => conv.conversation_id === Number(action.conversationID)
    )
    const existingMessage = conversation.messages?.find(
      (m) => m.id === action.message.id
    )
    if (!existingMessage) {
      const messages = conversation.messages?.slice() || []
      messages.unshift(action.message)
      const conversations = state.conversations.filter(
        (conv) => conv.conversation_id !== action.conversationID
      )
      conversation.messages = messages
      conversations.unshift(conversation)
      return { ...state, conversations }
    }
    return state
  }

  if (action.type === Actions.DELETE_CONVERSATION_MESSAGE) {
    const conversation = state.conversations.find(
      (conv) => conv.conversation_id === action.conversationID
    )
    conversation.messages = conversation.messages?.map((msg) => {
      if (msg.id === action.messageID) {
        msg.status_id = -3
        msg.body = ''
      }
      return msg
    })

    const conversations = state.conversations.map((conv) => {
      if (conv.conversation_id === action.conversationID) {
        return conversation
      }
      return conv
    })

    return { ...state, conversations }
  }

  if (action.type === Actions.SET_SHOW_MAKEINFLUENCE) {
    return Object.assign({}, state, {
      showMakeInfluence: action.payload,
    })
  }

  if (action.type === Actions.SET_USER_UNIVERSES) {
    return {
      ...state,
      user: {
        ...state.user,
        universes: [...state.user.universes, ...action.universes],
      },
    }
  }

  if (action.type === Actions.SET_USER_PREFERENCES) {
    return {
      ...state,
      preferences: action.preferences,
    }
  }

  if (action.type === Actions.UPDATE_USER_PREFERENCES) {
    return {
      ...state,
      preferences: { ...state.preferences, ...action.preferences },
    }
  }

  if (action.type === Actions.SET_GROUPS) {
    return {
      ...state,
      groups: action.groups,
    }
  }

  if (action.type === Actions.UPDATE_GROUP) {
    const groups = state.groups.map((group) => {
      return group.id === action.group.id
        ? { ...group, ...action.group }
        : group
    })
    return {
      ...state,
      groups,
    }
  }

  if (action.type === Actions.LEAVE_GROUP) {
    const groups = state.groups.filter((group) => {
      return group.id !== action.groupId
    })
    return {
      ...state,
      groups,
    }
  }

  if (action.type === Actions.SET_CHRISTMAS_POST) {
    return { ...state, christmas: {
      ...state.christmas,
      post: action.post
    }}
  }

  if (action.type === Actions.UPDATE_CHRISTMAS_POST) {
    return {
      ...state,
      christmas: {
        ...state.christmas,
        post: { ...state.christmas.post, ...action.post },
      },
    }
  }

  if (action.type === Actions.DELETE_CHRISTMAS_POST) {
    return { ...state, christmas: {
      ...state.christmas,
      post: null
    }}
  }

  if (action.type === Actions.SET_CHRISTMAS_DIALOG_VISIBLE) {
    return {
      ...state,
      christmas: {
        ...state.christmas,
        dialogVisible: action.dialogVisible,
      },
    }
  }

  if (action.type === Actions.SET_CHRISTMAS_MATCH_DIALOG_VISIBLE) {
    return {
      ...state,
      christmas: {
        ...state.christmas,
        matchDialogVisible: action.matchDialogVisible,
      },
    }
  }

  if (action.type === Actions.SET_GLOBAL_DIALOG) {
    return {
      ...state,
      globalDialog: action.globalDialog
    }
  }

  if (action.type === Actions.SET_MUNICIPALITIES) {
    return {
      ...state,
      municipalities: action.municipalities
    }
  }

  return state
}
export default rootReducer
