import React, { useEffect, useRef } from 'react'
import { Animated, StyleSheet } from 'react-native'

import Colors from '../../constants/Colors'

export const Skeleton = (props) => {
	const opacity = useRef(new Animated.Value(1)).current

	useEffect(() => {
		const options = { duration: 1000, useNativeDriver: true }
		const from = Animated.timing(opacity, { ...options, toValue: 0.4 })
		const to = Animated.timing(opacity, { ...options, toValue: 1 })

		const animation = Animated.loop(Animated.sequence([from, to]))
		animation.start()

		return () => animation.stop()
	}, [opacity])

	return <Animated.View style={[styles.skeleton, props.style ?? {}, { opacity }]} />
}

const styles = StyleSheet.create({
	skeleton: {
		backgroundColor: Colors.greyLightLight,
		borderRadius: 4,
		height: 20,
		width: 20,
	},
})
