import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import CustomDialog from '../dialogs/CustomDialog'
import { CustomText } from '../text/StyledText'
import { Platform, StyleSheet, View } from 'react-native'
import Colors from '../../constants/Colors'
import NormalIcon from '../icons/NormalIcon'
import { ButtonPrimary } from '../buttons/StyledButtons'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { txt } from '../../locales/i18n'
import CustomPressable from '../pressables/CustomPressable'
import { handlePaymentClick } from "../../utils/handlePaymentClick"
import { getSubscription } from "../../services/api/User"
import moment from "moment/moment"
import { trackPlausibleEvent } from "../../services/api/plausible"
import SubscriptionBenefits from "../shared/SubscriptionBenefits"

export default function SubscriptionWinBackDialog() {
	const userToken = useSelector((state) => state.userToken)
	const [visible, setVisible] = useState(false)
	const [subscription, setSubscription] = useState(null)

	useEffect(() => {
		getSubscription().then(setSubscription)
	}, [])

	useEffect(() => {
		if (userToken) {
			const dialogTimeout = setTimeout(openDialog, 10000)
			return () => clearTimeout(dialogTimeout)
		}
	}, [userToken, subscription])

	const openDialog = async () => {
		if (!subscription) return

		const cancelled = subscription?.state === 'active' && subscription?.is_cancelled === true
		if (cancelled === false) return

		if (!subscription.expires) return
		const expirationDate = moment(subscription.expires)

		const now = moment().format()
		const diffInDays = expirationDate.diff(now, 'days')
		if (diffInDays > 14) return

		let subscriptionWinBackFlag
		if (Platform.OS === 'web') subscriptionWinBackFlag = Cookies.get('subscriptionWinBackFlag')
		else subscriptionWinBackFlag = await AsyncStorage.getItem('subscriptionWinBackFlag')

		// To make sure the dialog is only shown once per subscription
		if (subscriptionWinBackFlag === subscription?.handle) return

		setVisible(true)
	}

	async function handleOpenUrl() {
		const origin = 'win-back-dialog'
		await trackPlausibleEvent('SubscriptionClick', { origin }, 'bobler')
		await handleCloseDialog()
		await handlePaymentClick({ origin })
	}

	async function handleCloseDialog() {
		if (Platform.OS !== 'web') await AsyncStorage.setItem('subscriptionWinBackFlag', subscription?.handle)
		else Cookies.set('subscriptionWinBackFlag', subscription?.handle, { expires: new Date(subscription.expires) })
		setVisible(false)
	}

	return (
		<CustomDialog visible={visible} transparent={true}>
			<View style={styles.dialogContainer}>
				<CustomPressable style={styles.closeIcon} accessibilityRole="button" onPress={handleCloseDialog}>
					<NormalIcon name="times" color={Colors.inactive} />
				</CustomPressable>
				<CustomText style={styles.title}>{txt('subscription.dialogs.winBack.title')}</CustomText>
				<CustomText style={{ marginTop: 12 }}>{txt('subscription.dialogs.winBack.intro')}</CustomText>
				<SubscriptionBenefits />
			</View>
			<View style={{ paddingBottom: 24 }}>
				<ButtonPrimary
					onPress={handleOpenUrl}
					style={{ backgroundColor: Colors.greenBtn }}
					title={txt('subscription.dialogs.subscriptionDialog.button')}
					titleStyle={{ paddingHorizontal: 12 }}
				/>
			</View>
		</CustomDialog>
	)
}

const styles = StyleSheet.create({
	dialogContainer: {
		margin: 24,
	},
	closeIcon: {
		alignSelf: 'flex-end',
		position: 'absolute',
		paddingLeft: 24,
		zIndex: 1,
	},
	title: {
		fontSize: 24,
		fontFamily: 'raleway-semibold',
		paddingRight: 25,
	},
})
